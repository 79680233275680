#hero-img-animation{
   animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
   0% {
      transform: translateY(5px);

   }

   100% {
      transform: translateY(-5px);

   }
}



/* 
.hero-animated-gif{
   background-image: url(../../../assets/HomeImg/hero-sape.png);
   background-repeat: no-repeat;
   background-size: cover;
} */