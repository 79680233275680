.Box1{
   background-image: url(../../../assets/HomeImg/WG8P.gif);
   background-repeat: no-repeat;
   background-size: cover;
   /* animation-duration: .3s; */
}


.Boxes-bg-img{
   background-image: url(../../../assets/Animated\ Shape.svg);
   background-repeat: no-repeat;
   background-size: cover;
}