*{
   margin: 0px;
   padding: 0px;
}

::-webkit-scrollbar {
   width: 10px;
   height: 10px;
 }
 
 ::-webkit-scrollbar-thumb {
   background: #00000000;
 }
 
 ::-webkit-scrollbar-track {
   background: #00000000;
 }